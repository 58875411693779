<template>
  <b-container class="c-page">
    <div class="c-page__title">
      <div>
        <h1>{{ $t('meetings.title') }}</h1>
      </div>
      <div class="c-page__controls">
        <b-icon v-if="role == 'admin'" class="clickable" icon="plus-square-fill" variant="primary"
                @click="addItem()" :title="$t('meetings.addMeeting')"></b-icon>
      </div>
    </div>

    <div class="row">
      <div class="c-page__table-scrollable-wrap col-12">
        <table class="table c-page__table">
          <thead>
          <tr>
            <th></th>
            <th>{{ $t('meetings.tableDateCol') }}</th>
            <th>{{ $t('meetings.tableName') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="item in posts">
            <tr :key="item.id">
              <td class="c-page__table-control-col">
                <b-icon v-if="nextMeeting(item.date.date)" icon="bookmark" variant="primary"></b-icon>
                <b-icon v-else icon="bookmark-check" variant="primary"></b-icon>
              </td>
              <td>
                                <span v-if="item.date !== null">
                                {{ item.date.date | moment("DD/MM/YYYY") }}
                                </span>
              </td>
              <td>

                <router-link v-if="role == 'meetings-only'" :to="{name: 'meetingsEdit', params: {id: item.id}}">
                  {{ item.name }}
                </router-link>
                <span v-else>
                  {{ item.name }}
                </span>
              </td>
              <td class="text-right c-page__table-edit">
                <router-link v-if="role == 'admin' || role == 'meetings-only'" :to="{name: 'meetingsEdit', params: {id: item.id}}">
                  <b-icon v-if="role == 'admin'" class="clickable" icon="pencil-square" variant="primary"></b-icon>
                  <b-icon v-if="role == 'meetings-only'" class="clickable" icon="eye" variant="primary"></b-icon>
                </router-link>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>


    <router-view @loadData="loadData()"></router-view>
  </b-container>

</template>

<script>

export default {
  name: 'meetings',
  components: {},
  data() {
    return {
      posts: [],
    }
  },
  methods: {
    async loadData() {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/meetings/list.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          method: 'GET'
        });

        this.posts = response.data;

      } catch (error) {
        console.log("error", error);
        this.$eventHub.$emit('openMessageController', 'error', 'no data');
      }

    },
    async addItem() {

      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/meetings/add-item.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          method: 'POST'
        });

        await this.$router.push({name: 'meetingsEdit', params: {id: response.data.id}});
        await this.loadData();

      } catch (error) {
        await this.loadData();
        console.log("error", error);
      }

    },
    nextMeeting(meetingDate) {
      const currentDate = new Date();
      const meetingDateFixed = new Date(meetingDate);
      if (currentDate < meetingDateFixed) {
        return true;
      } else {
        return false;
      }
    }


  },
  computed: {
    role: function () {
      try {
        return this.$store.getters.user.permission;
      } catch (err) {
        return false
      }
    },
  },
  mounted() {
    this.loadData();

  }
}
</script>

<style lang="scss" scoped>

</style>
